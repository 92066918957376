export const departamentos = {
    AMBATO: 'Ambato' ,
    ANCASTI: 'Ancasti',
    ANDALGALA: 'Andalgalá', 
    ANFOGASTA: 'Antofagasta de la Sierra', 
    BELEN: 'Belén', 
    CAPAYAN: 'Capayán', 
    CAPITAL: 'Capital', 
    EL_ALTO: 'El Alto', 
    FRAY: 'Fray Mamerto Esquiú', 
    LA_PAZ: 'La Paz', 
    PACLIN: 'Paclín', 
    POMAN: 'Pomán', 
    SANTA_MARIA: 'Santa María', 
    SANTA_ROSA: 'Santa Rosa', 
    TINOGASTA: 'Tinogasta', 
    VALLE_VIEJO: 'Valle Viejo'
};