const types = {
    NUEVO_ENLACE: 'NUEVO_ENLACE',
    NUEVO_ENLACE_SUCCESS: 'NUEVO_ENLACE_SUCCESS',
    NUEVO_ENLACE_ERROR: 'NUEVO_ENLACE_ERROR',

    UPLOAD_ENLACE: 'UPLOAD_ENLACE',
    UPLOAD_ENLACE_SUCCESS: 'UPLOAD_ENLACE_SUCCESS',
    UPLOAD_ENLACE_ERROR: 'UPLOAD_ENLACE_ERROR',

    UPLOAD_ENLACES: 'UPLOAD_ENLACES',
    UPLOAD_ENLACES_COMMENT: 'UPLOAD_ENLACES_COMMENT',
    UPLOAD_ENLACES_SUCCESS: 'UPLOAD_ENLACES_SUCCESS',
    UPLOAD_ENLACES_ERROR: 'UPLOAD_ENLACES_ERROR',

    DELETE_ENLACE: 'DELETE_ENLACE',
    DELETE_ENLACE_SUCCESS: 'DELETE_ENLACE_SUCCESS',
    DELETE_ENLACE_ERROR: 'DELETE_ENLACE_ERROR',

    GET_ENLACES: 'GET_ENLACES',
    GET_ENLACES_SUCCESS: 'GET_ENLACES_SUCCESS',
    GET_ENLACES_ERROR: 'GET_ENLACES_ERROR',

    GET_ENLACE: 'GET_ENLACE',

    SET_FIRST_ENLACE: 'SET_FIRST_ENLACE',
    SET_LAST_ENLACE: 'SET_LAST_ENLACE',
    SET_PAGE_ENLACE: 'SET_PAGE_ENLACE',

    CLEAR_STATUS: 'CLEAR_STATUS',


    CLEAR_ENLACES: 'CLEAR_ENLACES'
}

export default types