const types = {

    NUEVO_ASESORAMIENTO: 'NUEVO_ASESORAMIENTO',
    NUEVO_ASESORAMIENTO_SUCCESS: 'NUEVO_ASESORAMIENTO_SUCCESS',
    NUEVO_ASESORAMIENTO_ERROR: 'NUEVO_ASESORAMIENTO_ERROR',

    UPLOAD_IMG: 'UPLOAD_IMG',
    UPLOAD_IMG_SUCCESS: 'UPLOAD_IMG_SUCCESS',
    UPLOAD_IMG_ERROR: 'UPLOAD_IMG_ERROR',

    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',

    CLEAR_STATUS: 'CLEAR_STATUS',

    UPLOAD_ASESORAMIENTO: 'UPLOAD_ASESORAMIENTO',
    UPLOAD_ASESORAMIENTO_SUCCESS: 'UPLOAD_ASESORAMIENTO_SUCCESS',
    UPLOAD_ASESORAMIENTO_ERROR: 'UPLOAD_ASESORAMIENTO_ERROR',

    GET_ASESORAMIENTOS_USER: 'GET_ASESORAMIENTOS_USER',
    GET_ASESORAMIENTOS_USER_SUCCESS: 'GET_ASESORAMIENTOS_USER_SUCCESS',
    GET_ASESORAMIENTOS_USER_ERROR: 'GET_ASESORAMIENTOS_USER_ERROR',

    GET_ASESORAMIENTOS: 'GET_ASESORAMIENTOS',
    GET_ASESORAMIENTOS_SUCCESS: 'GET_ASESORAMIENTOS_SUCCESS',
    GET_ASESORAMIENTOS_ERROR: 'GET_ASESORAMIENTOS_ERROR',

    SET_FIRST_ASESORAMIENTO: 'SET_FIRST_ASESORAMIENTO',
    SET_LAST_ASESORAMIENTO: 'SET_LAST_ASESORAMIENTO',
    SET_PAGE_ASESORAMIENTO: 'SET_PAGE_ASESORAMIENTO',

    GET_ASESORAMIENTO: 'GET_ASESORAMIENTO',
    
    DELETE_ASESORAMIENTO: 'DELETE_ASESORAMIENTO',
    DELETE_ASESORAMIENTO_SUCCESS: 'DELETE_ASESORAMIENTO_SUCCESS',
    DELETE_ASESORAMIENTO_ERROR: 'DELETE_ASESORAMIENTO_ERROR',

    CLEAR_ASESORAMIENTOS: 'CLEAR_ASESORAMIENTOS',
}

export default types