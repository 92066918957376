// USER TYPES
export const AUTHENTICATE_ADMIN = 'AUTHENTICATE_ADMIN'
export const AUTHENTICATE_ADMIN_SUCCESS = 'AUTHENTICATE_ADMIN_SUCCESS'
export const AUTHENTICATE_ADMIN_ERROR = 'AUTHENTICATE_ADMIN_ERROR'

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'
export const AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR'

export const SET_USER = 'SET_USER';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export const GET_VOTE_PLACE = 'GET_VOTE_PLACE';
export const GET_VOTE_PLACE_SUCCESS = 'GET_VOTE_PLACE_SUCCESS';
export const GET_VOTE_PLACE_ERROR = 'GET_VOTE_PLACE_ERROR';

export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';
export const ADMIN_LOGOUT_ERROR = 'ADMIN_LOGOUT_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const SET_USER_SESSION = 'SET_USER_SESSION';

export const CLEAR_USER_STATUS = 'CLEAR_PROFILE_STATUS';
export const APROVE = 'APROVE';

export const SET_PROFILE = 'SET_PROFILE';


