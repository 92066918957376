const types = {

    NUEVA_NOVEDAD: 'NUEVA_NOVEDAD',
    NUEVA_NOVEDAD_SUCCESS: 'NUEVA_NOVEDAD_SUCCESS',
    NUEVA_NOVEDAD_ERROR: 'NUEVA_NOVEDAD_ERROR',

    UPLOAD_IMG: 'UPLOAD_IMG',
    UPLOAD_IMG_SUCCESS: 'UPLOAD_IMG_SUCCESS',
    UPLOAD_IMG_ERROR: 'UPLOAD_IMG_ERROR',

    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',

    CLEAR_STATUS: 'CLEAR_STATUS',

    UPLOAD_NOVEDAD: 'UPLOAD_NOVEDAD',
    UPLOAD_NOVEDAD_SUCCESS: 'UPLOAD_NOVEDAD_SUCCESS',
    UPLOAD_NOVEDAD_ERROR: 'UPLOAD_NOVEDAD_ERROR',

    GET_NOVEDADES_USER: 'GET_NOVEDADES_USER',
    GET_NOVEDADES_USER_SUCCESS: 'GET_NOVEDADES_USER_SUCCESS',
    GET_NOVEDADES_USER_ERROR: 'GET_NOVEDADES_USER_ERROR',

    GET_NOVEDADES: 'GET_NOVEDADES',
    GET_NOVEDADES_SUCCESS: 'GET_NOVEDADES_SUCCESS',
    GET_NOVEDADES_ERROR: 'GET_NOVEDADES_ERROR',

    DELETE_NOVEDADES: 'DELETE_NOVEDADES',
    DELETE_NOVEDADES_SUCCESS: 'DELETE_NOVEDADES_SUCCESS',
    DELETE_NOVEDADES_ERROR: 'DELETE_NOVEDADES_ERROR',

    SET_FIRST_NOVEDAD: 'SET_FIRST_NOVEDAD',
    SET_LAST_NOVEDAD: 'SET_LAST_NOVEDAD',
    SET_PAGE_NOVEDAD: 'SET_PAGE_NOVEDAD',

    GET_NOVEDAD: 'GET_NOVEDAD',


    CLEAR_NOVEDADES: 'CLEAR_NOVEDADES',
}

export default types