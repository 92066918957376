const types = {

    NUEVA_CUOTA: 'NUEVA_CUOTA',
    NUEVA_CUOTA_SUCCESS: 'NUEVA_CUOTA_SUCCESS',
    NUEVA_CUOTA_ERROR: 'NUEVA_CUOTA_ERROR',

    UPLOAD_CUOTA: 'UPLOAD_CUOTA',
    UPLOAD_CUOTA_SUCCESS: 'UPLOAD_CUOTA_SUCCESS',
    UPLOAD_CUOTA_ERROR: 'UPLOAD_CUOTA_ERROR',

    GET_CUOTAS: 'GET_CUOTAS',
    GET_CUOTAS_SUCCESS: 'GET_CUOTAS_SUCCESS',
    GET_CUOTAS_ERROR: 'GET_CUOTAS_ERROR',

    DELETE_CUOTAS: 'DELETE_CUOTAS',
    DELETE_CUOTAS_SUCCESS: 'DELETE_CUOTAS_SUCCESS',
    DELETE_CUOTAS_ERROR: 'DELETE_CUOTAS_ERROR',

    SET_USER_CUOTAS: 'SET_USER_CUOTAS',
    SET_USER_CUOTAS_SUCCESS: 'SET_USER_CUOTAS_SUCCESS',
    SET_USER_CUOTAS_ERROR: 'SET_USER_CUOTAS_ERROR',

    SET_USER_SESSION: 'SET_USER_SESSION',

    CLEAR_CUOTAS_STATUS: 'CLEAR_CUOTAS_STATUS',
    APROVE: 'APROVE',

    SET_FIRST_CUOTA: 'SET_FIRST_CUOTA',
    SET_LAST_CUOTA: 'SET_LAST_CUOTA',
    SET_PAGE_CUOTA: 'SET_PAGE_CUOTA',

    GET_CUOTA: 'GET_CUOTA',
    

    CLEAR_CUOTAS: 'CLEAR_CUOTAS'
}

export default types

