const types = {

    NUEVA_TRANSACCION: 'NUEVA_TRANSACCION',
    NUEVA_TRANSACCION_SUCCESS: 'NUEVA_TRANSACCION_SUCCESS',
    NUEVA_TRANSACCION_ERROR: 'NUEVA_TRANSACCION_ERROR',

    GET_TRANSACCIONES: 'GET_TRANSACCIONES',
    GET_TRANSACCIONES_SUCCESS: 'GET_TRANSACCIONES_SUCCESS',
    GET_TRANSACCIONES_ERROR: 'GET_TRANSACCIONES_ERROR',

    GET_USER_CUOTAS: 'GET_USER_CUOTAS',
    GET_USER_CUOTAS_SUCCESS: 'GET_USER_CUOTAS_SUCCESS',
    GET_USER_CUOTAS_ERROR: 'GET_USER_CUOTAS_ERROR',

    SET_FIRST_TRANSACCION: 'SET_FIRST_TRANSACCION',
    SET_LAST_TRANSACCION: 'SET_LAST_TRANSACCION',
    SET_PAGE_TRANSACCION: 'SET_PAGE_TRANSACCION',

    CLEAR_TRANSACCIONES_STATUS: 'CLEAR_TRANSACCIONES_STATUS',

    GET_TRANSACCION: 'GET_TRANSACCION',
    

    CLEAR_TRANSACCIONES: 'CLEAR_TRANSACCIONES'
}

export default types