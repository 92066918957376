import React from 'react'
import styles from './styles.module.css';

const Novedades = () => {
    return (
        <div className={styles.textContainer}>
            <p className={styles.text}>No hay novedades</p>
        </div>
    )
}

export default Novedades
